import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import './index.css';


import { Toaster } from '@/components/ui/toaster';
import NotFoundPage from './pages/NotFoundPage';
import { EquipmentItemPageLazy, EquipmentPageLazy, ExercisePageLazy, GymPageLazy, GymsPageLazy, ForGymsPageLazy, TrainerPageLazy, TrainersPageLazy, HomePageLazy } from './pages/lazy';
import Loading from './components/Loading';
import * as Sentry from '@sentry/react';
import { DOMAIN, SENTRY_DSN } from './constants/app';
import { Analytics } from '@vercel/analytics/react';

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [DOMAIN],
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.25,
  replaysOnErrorSampleRate: 1.0,
});

if (import.meta.env.PROD) {
  import('react-ga4').then(({ default: ReactGA }) => {
    ReactGA.initialize([
      {
        trackingId: 'G-SEBM4G56HE'
      }
    ]);
  });
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePageLazy />,
  },
  {
    path: '/for-gyms',
    element: <ForGymsPageLazy />
  },
  {
    path: '/gyms',
    element: <GymsPageLazy />
  },
  {
    path: '/gyms/:gymSlug',
    element: <GymPageLazy />
  },
  {
    path: '/gyms/:gymSlug/equipment',
    element: <EquipmentPageLazy />
  },
  {
    path: '/gyms/:gymSlug/equipment/:equipmentSlug',
    element: <EquipmentItemPageLazy />
  },
  // {
  //   path: '/gyms/:gymSlug/equipment/:equipmentSlug/exercises',
  //   element: <ExercisesPage />
  // },
  {
    path: '/gyms/:gymSlug/equipment/:equipmentSlug/:exerciseSlug',
    element: <ExercisePageLazy />
  },
  {
    path: '/gyms/:gymSlug/trainers',
    element: <TrainersPageLazy />
  },
  {
    path: '/gyms/:gymSlug/trainers/:trainerSlug',
    element: <TrainerPageLazy />
  },
  {
    path: '*',
    element: <NotFoundPage />
  }
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <RouterProvider router={router} />
    </Suspense>
    <Toaster />
    <Analytics />
  </React.StrictMode>,
);
