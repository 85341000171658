import { lazy } from 'react';

export const HomePageLazy = lazy(() => import('./HomePage'));
export const ForGymsPageLazy = lazy(() => import('./ForGymsPage'));
export const GymsPageLazy = lazy(() => import('./GymsPage'));
export const GymPageLazy = lazy(() => import('./GymPage'));
export const EquipmentPageLazy = lazy(() => import('./EquipmentPage'));
export const EquipmentItemPageLazy = lazy(() => import('./EquipmentItemPage'));
export const ExercisePageLazy = lazy(() => import('./ExercisePage'));
export const TrainersPageLazy = lazy(() => import('./TrainersPage'));
export const TrainerPageLazy = lazy(() => import('./TrainerPage'));