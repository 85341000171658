import { FC, useEffect, useState } from 'react';
import { Button } from '@/components/ui/button'
import { RxChevronLeft, RxShare2 } from 'react-icons/rx';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useToast } from '@/components/ui/use-toast';
import Logo from '../assets/logo.svg?react';
// import { useParams } from 'react-router-dom';

interface HeaderProps {
  hideBackButton?: boolean;
  hideShareButton?: boolean;
}

const Header: FC<HeaderProps> = ({ hideBackButton, hideShareButton }) => {

  // const params = useParams();
  const [locationSegments, setLocationSegments] = useState<string[]>([]);
  const navigate = useNavigate();
  const loc = useLocation();

  useEffect(() => {
    const segments = loc.pathname.split('/').slice(1, -1);
    setLocationSegments(segments);
  }, [loc.pathname]);

  const { toast } = useToast();

  const goBack = () => {
    const segments = loc.pathname.split('/').slice(1, -1);
    const url = `/${segments.join('/')}`
    navigate(url);
  };

  const copyUrl = () => {
    const url = new URL(window.location.href);
    url.searchParams.set('utm_source', 'app-share');
    url.searchParams.set('utm_medium', 'app');

    try {
      navigator.clipboard.writeText(url.toString());
      toast({
        title: 'URL Copied!',
        description: 'Simply paste the URL to share this page with others.',
      });
    } catch (error) {
      toast({
        title: 'Could not copy URL!',
        description: 'Your device does not support copying to clipboard.',
      });
    }
  };

  return (
    <header className="flex items-center justify-between space-x-2 p-3">
      {!hideBackButton && locationSegments.length > 0 && (
        <Button variant="outline" onClick={goBack}>
          <RxChevronLeft />
        </Button>
      )}
      <div className="flex-grow logo">
        <Link to="/">
          <Logo className="h-10 mx-auto" />
        </Link>
      </div>
      {!hideShareButton && locationSegments.length > 0 && (
        <Button variant="outline" onClick={copyUrl}>
          <RxShare2 />
        </Button>
      )}
    </header>
  )
}

export default Header;