import { FC } from 'react';
import Header from '../components/Header';
import * as NotFoundSvg from '../assets/404.svg';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';

const NotFoundPage: FC = () => {
  return (
    <>
      <Header hideBackButton={true} hideShareButton={true} />

      <main className="flex flex-col justify-center h-full container mx-auto p-2 text-center">
        <h1 className="text-5xl font-black text-primary">404</h1>
        <p className="text-xl font-semibold">Page not found</p>
        <img src={NotFoundSvg.default} alt="" className="w-full h-48 my-16" />
        <Link to="/gyms">
          <Button className='w-36 mx-auto'>Explore Gyms</Button>
        </Link>
      </main>
    </>
  )
}

export default NotFoundPage;